import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import SpacingContent from "../../../components/SpacingContent";
import { ArrowCircleRightOutlined } from "@mui/icons-material";
import award from "../../../assets/media/images/award/award_1.jpeg";
import { colors } from "../../../assets/utils/colors";
import SectionBgGradient from "../../../components/SectionBgGradient";
import EventCountDown from "../../../components/EventCountDown";
import { Link } from "react-router-dom";
import logo1 from "../../../assets/media/images/award/logo1.jpg";
import logo2 from "../../../assets/media/images/award/logo2.jpg";
import logo3 from "../../../assets/media/images/award/logo3.jpg";
import logo4 from "../../../assets/media/images/award/logo4.jpg";
import logo5 from "../../../assets/media/images/award/logo5.jpg";
import logo6 from "../../../assets/media/images/award/logo6.jpg";
import logo7 from "../../../assets/media/images/award/logo7.jpg";
import logo8 from "../../../assets/media/images/award/logo8.jpg";
import logo9 from "../../../assets/media/images/award/logo9.jpg";
import logo10 from "../../../assets/media/images/award/logo10.jpg";
import moment from "moment";

const UpcomingEventBanner = ({ event }) => {
  const logos = [
    {
      id: 1,
      img: logo1,
    },
    {
      id: 2,
      img: logo2,
    },
    {
      id: 3,
      img: logo3,
    },
    {
      id: 4,
      img: logo4,
    },
    {
      id: 5,
      img: logo5,
    },
    {
      id: 6,
      img: logo6,
    },
    {
      id: 7,
      img: logo7,
    },
    {
      id: 8,
      img: logo8,
    },
    {
      id: 9,
      img: logo9,
    },
    {
      id: 10,
      img: logo10,
    },
  ];
  return (
    <>
      <SpacingContent>
        <Box
          sx={{
            position: "relative",
            border: `10px double ${colors.secondary}`,
            borderRadius: 10,
            overflow: "hidden",
            mt: 10,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              background: `linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1))`,
              borderBottomRightRadius: 20,
            }}
          >
            <Box sx={{ p: 2 }}>
              <Typography
                sx={{
                  color: colors.secondary,
                  fontWeight: "bold",
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
              >
                Upcoming event
              </Typography>
              <Typography
                sx={{
                  color: colors.secondary,
                  textAlign: "center",
                  mt: 1,
                }}
              >
                {moment(event.event_date).format("LL")}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              height: { md: "80vh", sm: "80vh", xs: "100%" },
              display: "flex",
              justifyContent: "cente",
              alignItems: "center",
              mt: { md: "auto", sm: "auto", xs: 8 },
            }}
          >
            <Grid container>
              <Grid item sm={6} xs={12} sx={{ pt: 10 }}>
                <Box
                  sx={{
                    height: {
                      md: "80vh",
                      sm: "80vh",
                      xs: "100%",
                    },
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    px: { md: 8, sm: 8, xs: 2 },
                    position: "relative",
                  }}
                >
                  <SectionBgGradient />
                  <Box>
                    <Typography
                      sx={{
                        fontSize: {
                          md: 50,
                          sm: 50,
                          xs: 30,
                        },
                        fontWeight: "bold",
                        fontStyle: "italic",
                        textTransform: "uppercase",
                        background: `linear-gradient(rgba(213, 138, 0, 1), rgba(0, 0, 0, .5))`,
                        textFillColor: "transparent",
                        backgroundClip: "text",
                        lineHeight: 1.2,
                      }}
                      className=""
                    >
                      {event.event_title}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      zIndex: 50,
                      mb: { md: 15, sm: 15, xs: 0 },
                    }}
                    gap={2}
                  >
                    <Link to={`/event-portifolios/${event.id}`}>
                      <Button
                        color="secondary"
                        sx={{
                          borderRadius: `50px !important`,
                          pr: {
                            md: 10,
                            sm: 10,
                            xs: 8,
                          },
                          my: 2,
                          pl: { md: 5, sm: 5, xs: 3 },
                          py: 1.5,
                          boxShadow: `0px 0px 5px ${colors.secondary}`,
                          border: `1px double ${colors.secondary}`,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: {
                              md: 16,
                              sm: 16,
                              xs: 14,
                            },
                            fontWeight: "bold",
                          }}
                        >
                          Learn more
                        </Typography>
                        <ArrowCircleRightOutlined
                          sx={{
                            position: "absolute",
                            right: 15,
                            fontSize: {
                              md: 35,
                              sm: 35,
                              xs: 30,
                            },
                            color: colors.secondary,
                          }}
                        />
                      </Button>
                    </Link>
                    {/* <a
                                            href="https://docs.google.com/forms/d/e/1FAIpQLSdgCcRHsYpmuQgaHCaiFvvQ49VjFDPNUIy138rqLXNJCpWoWQ/viewform?usp=sf_link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                sx={{
                                                    borderRadius: `50px !important`,
                                                    pr: {
                                                        md: 10,
                                                        sm: 10,
                                                        xs: 8,
                                                    },
                                                    my: 5,
                                                    pl: { md: 5, sm: 5, xs: 3 },
                                                    py: 1.5,
                                                    boxShadow: `0px 0px 5px ${colors.secondary}`,
                                                    border: `1px double ${colors.secondary}`,
                                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            md: 16,
                                                            sm: 16,
                                                            xs: 14,
                                                        },
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    vote now!
                                                </Typography>
                                                <ArrowCircleRightOutlined
                                                    sx={{
                                                        position: "absolute",
                                                        right: 15,
                                                        fontSize: {
                                                            md: 35,
                                                            sm: 35,
                                                            xs: 30,
                                                        },
                                                        color: colors.secondary,
                                                    }}
                                                />
                                            </Button>
                                        </a> */}
                  </Box>
                  <Box
                    sx={{
                      position: {
                        md: "absolute",
                        sm: "absolute",
                        xs: "relative",
                      },
                      bottom: 0,
                      left: { md: 50, sm: 50, xs: 0 },
                      right: { md: 50, sm: 50, xs: 0 },
                      mt: 2,
                      mb: 5,
                      borderRadius: 5,
                      pb: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        mb: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          p: 1,
                          textAlign: "center",
                          fontSize: 14,
                          fontWeight: "bold",
                          color: "red",
                          background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor4})`,
                        }}
                      >
                        The remaining time until the event:
                      </Typography>
                    </Box>
                    <EventCountDown eventDate={event.event_date} />
                  </Box>
                </Box>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Box
                  sx={{
                    height: {
                      md: "100%",
                      sm: "100%",
                      xs: "50vh",
                    },
                    display: "flex",
                    justifyContent: "cente",
                    alignItems: "center",
                    borderTopLeftRadius: 500,
                    backgroundImage: `url(${event.tease_image})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: `100% 100%`,
                    position: "relative",
                    borderLeft: `20px double ${colors.secondary}`,
                  }}
                >
                  {/* <Box
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        top: 0,
                        overflow: "hidden",
                      }}
                    >
                      <marquee
                        behavior="scroll"
                        direction="up"
                        scrollamount="5"
                        style={{
                          height: "100%",
                          width: 100,
                          textAlign: "center",
                          position: "relative",
                          borderTopLeftRadius: 10,
                        }}
                      >
                        <center>
                          {logos.map((logo, index) => (
                            <Avatar
                              key={index}
                              src={logo.img}
                              sx={{
                                height: 80,
                                width: 80,
                                mb: 4,
                              }}
                            />
                          ))}
                        </center>
                      </marquee>
                    </Box> */}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </SpacingContent>
    </>
  );
};

export default UpcomingEventBanner;
