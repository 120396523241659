import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import {
  ArrowCircleRightOutlined,
  Description,
  ReadMore,
} from "@mui/icons-material";
import award from "../../assets/media/images/award/award_1.jpeg";
import { colors } from "../../assets/utils/colors";
import SectionBgGradient from "../../components/SectionBgGradient";
import EventCountDown from "../../components/EventCountDown";
import { Link, useParams } from "react-router-dom";
import logo1 from "../../assets/media/images/award/logo1.jpg";
import logo2 from "../../assets/media/images/award/logo2.jpg";
import logo3 from "../../assets/media/images/award/logo3.jpg";
import logo4 from "../../assets/media/images/award/logo4.jpg";
import logo5 from "../../assets/media/images/award/logo5.jpg";
import logo6 from "../../assets/media/images/award/logo6.jpg";
import logo7 from "../../assets/media/images/award/logo7.jpg";
import logo8 from "../../assets/media/images/award/logo8.jpg";
import logo9 from "../../assets/media/images/award/logo9.jpg";
import logo10 from "../../assets/media/images/award/logo10.jpg";
import Section from "../../components/Section";
import SpacingContent from "../../components/SpacingContent";
import GallaryView from "../../components/GallaryView";
import { setEventDetails } from "../../state/features/events/eventsSlice";
import { useGetPublicEventByIdQuery } from "../../api/eventAPI/eventAPI";
import { eventsSelector } from "../../state/features/selectors";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import useRTK from "../../hooks/useRTK";
import NoContent from "../../components/NoContent";
import moment from "moment";
import EventGallaryView from "../../components/EventGallaryView";

const EventDetailsPage = () => {
  // ############### CONST ####################
  const deviceWidth = useMediaQuery("(min-width:600px)");
  const [toggler, setToggler] = React.useState(false);
  const [gallaryView, setGallaryView] = React.useState(null);
  const { event_id } = useParams();
  const dispatch = useDispatch();
  const RTK = useRTK();

  // ############### Redux State ###################
  const { eventDetails } = useSelector(eventsSelector);

  // ############ RTK ###################
  const {
    isLoading,
    isSuccess,
    data: responseData,
  } = useGetPublicEventByIdQuery(event_id);

  // ############# RTK response ##################
  const rtkActions = (data) => {
    dispatch(setEventDetails(data));
  };
  RTK.useRTKFetch(isSuccess, responseData, rtkActions);

  // ############## FUNC ######################
  const handleGallaryView = (gallary) => {
    setToggler(!toggler);
    setGallaryView(gallary);
  };

  const logos = [
    {
      id: 1,
      img: logo1,
    },
    {
      id: 2,
      img: logo2,
    },
    {
      id: 3,
      img: logo3,
    },
    {
      id: 4,
      img: logo4,
    },
    {
      id: 5,
      img: logo5,
    },
    {
      id: 6,
      img: logo6,
    },
    {
      id: 7,
      img: logo7,
    },
    {
      id: 8,
      img: logo8,
    },
    {
      id: 9,
      img: logo9,
    },
    {
      id: 10,
      img: logo10,
    },
  ];

  return (
    <>
      <EventGallaryView toggler={toggler} gallary={gallaryView} />

      {isEmpty(eventDetails) ? (
        <>
          <NoContent message={"Event details not found"} />
        </>
      ) : (
        <>
          {/* ############ BANNER ############# */}
          <Box
            sx={{
              position: "relative",
              height: { md: "75vh", sm: "75vh", xs: "100%" },
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                background: `linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1))`,
                borderBottomRightRadius: 20,
              }}
            >
              {moment(eventDetails.event_date) >= moment(new Date()) && (
                <Box sx={{ p: 2, zIndex: 100 }}>
                  <Typography
                    sx={{
                      color: colors.secondary,
                      fontWeight: "bold",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  >
                    Upcoming event
                  </Typography>
                  <Typography
                    sx={{
                      color: colors.secondary,
                      textAlign: "center",
                      mt: 1,
                    }}
                  >
                    {moment(eventDetails.event_date).format("LL")}
                  </Typography>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                height: { md: "75vh", sm: "75vh", xs: "100%" },
                display: "flex",
                justifyContent: "cente",
                alignItems: "center",
                mt: { md: "auto", sm: "auto", xs: 8 },
              }}
            >
              <Grid container>
                <Grid item sm={6} xs={12} sx={{ pt: 10 }}>
                  <Box
                    sx={{
                      height: {
                        md: "75vh",
                        sm: "75vh",
                        xs: "100%",
                      },
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      px: { md: 8, sm: 8, xs: 2 },
                      position: "relative",
                    }}
                  >
                    <SectionBgGradient />
                    <Box>
                      <Typography
                        sx={{
                          fontSize: {
                            md: 50,
                            sm: 50,
                            xs: 30,
                          },
                          fontWeight: "bold",
                          fontStyle: "italic",
                          textTransform: "uppercase",
                          background: `linear-gradient(rgba(213, 138, 0, 1), rgba(0, 0, 0, .5))`,
                          textFillColor: "transparent",
                          backgroundClip: "text",
                          lineHeight: 1.2,
                        }}
                        className=""
                      >
                        {eventDetails.event_title}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        zIndex: 50,
                        mb: { md: 15, sm: 15, xs: 0 },
                      }}
                      gap={2}
                    ></Box>
                    <Box
                      sx={{
                        position: {
                          md: "absolute",
                          sm: "absolute",
                          xs: "relative",
                        },
                        bottom: 0,
                        left: { md: 50, sm: 50, xs: 0 },
                        right: { md: 50, sm: 50, xs: 0 },
                        mt: 2,
                        mb: 5,
                        borderRadius: 5,
                        pb: 2,
                      }}
                    >
                      {moment(eventDetails.event_date) >=
                        moment(new Date()) && (
                        <Box
                          sx={{
                            display: "flex",
                            mb: 1,
                          }}
                        >
                          <Typography
                            sx={{
                              p: 1,
                              textAlign: "center",
                              fontSize: 14,
                              fontWeight: "bold",
                              color: "red",
                              background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor4})`,
                            }}
                          >
                            The remaining time until the event:
                          </Typography>
                        </Box>
                      )}
                      <EventCountDown eventDate={eventDetails.event_date} />
                    </Box>
                  </Box>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Box
                    sx={{
                      height: {
                        md: "100%",
                        sm: "100%",
                        xs: "50vh",
                      },
                      display: "flex",
                      justifyContent: "cente",
                      alignItems: "center",
                      borderTopLeftRadius: 500,
                      backgroundImage: `url(${eventDetails.tease_image})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: `120% 100%`,
                      position: "relative",
                      borderLeft: `20px double ${colors.secondary}`,
                    }}
                  >
                    {/* <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    top: 0,
                    overflow: "hidden",
                  }}
                >
                  <marquee
                    behavior="scroll"
                    direction="up"
                    scrollamount="5"
                    style={{
                      height: "100%",
                      width: 100,
                      textAlign: "center",
                      position: "relative",
                      borderTopLeftRadius: 10,
                    }}
                  >
                    <center>
                      {logos.map((logo, index) => (
                        <Avatar
                          key={index}
                          src={logo.img}
                          sx={{
                            height: 80,
                            width: 80,
                            mb: 4,
                          }}
                        />
                      ))}
                    </center>
                  </marquee>
                </Box> */}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>

          {/* ############ CONTENT ############ */}
          <SpacingContent>
            <Grid container spacing={{ md: 5, sm: 5, xs: 2 }}>
              <Grid item sm={3} xs={12}>
                <Box
                  sx={{
                    p: 2,
                    height: "100%",
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                    borderBottom: `5px solid ${colors.secondary}`,
                  }}
                >
                  <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                    Event Type
                  </Typography>
                  <Typography sx={{ fontSize: 15, mt: 1 }}>
                    {eventDetails.event_type.title}
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={3} xs={12}>
                <Box
                  sx={{
                    p: 2,
                    height: "100%",
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                    borderBottom: `5px solid ${colors.secondary}`,
                  }}
                >
                  <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                    Event Client
                  </Typography>
                  <Typography sx={{ fontSize: 14 }}>
                    {eventDetails.client.name}
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={3} xs={12}>
                <Box
                  sx={{
                    p: 2,
                    height: "100%",
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                    borderBottom: `5px solid ${colors.secondary}`,
                  }}
                >
                  <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                    Event date
                  </Typography>
                  <Typography sx={{ fontSize: 15, mt: 1 }}>
                    {moment(eventDetails.event_date).format("LL")}
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={3} xs={12}>
                <Box
                  sx={{
                    p: 2,
                    height: "100%",
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                    borderBottom: `5px solid ${colors.secondary}`,
                  }}
                >
                  <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                    Venue
                  </Typography>
                  <Typography sx={{ fontSize: 14 }}>
                    {eventDetails.event_venue}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Section head={eventDetails.event_title}>
              <Grid container justifyContent={"center"}>
                <Grid item sm={12} xs={11}>
                  <Typography sx={{ fontSize: 16, lineHeight: 1.8 }}>
                    {eventDetails.event_description}
                  </Typography>
                </Grid>
              </Grid>
            </Section>
          </SpacingContent>

          {/* ######### Gallary ######### */}
          {!isEmpty(eventDetails?.gallaries) && (
            <SpacingContent>
              <Section head={"Event Gallaries"}>
                <Grid container justifyContent={"center"}>
                  <Grid item sm={12} xs={11}>
                    <ImageList
                      gap={deviceWidth ? 15 : 15}
                      cols={deviceWidth ? 4 : 2}
                    >
                      {eventDetails.gallaries?.map((gallary, index) => (
                        <ImageListItem
                          key={index}
                          sx={{
                            borderRadius: { md: 2, xs: 2 },
                            overflow: "hidden",
                            cursor: "pointer",
                          }}
                          onClick={() => handleGallaryView(gallary)}
                        >
                          <img
                            src={`${gallary.image}?w=164&h=164&fit=crop&auto=format`}
                            srcSet={`${gallary.image}?w=164&h=164&fit=crop&auto=format`}
                            alt={gallary.event}
                            loading="lazy"
                            style={{
                              height: deviceWidth ? 250 : 120,
                            }}
                          />
                          <ImageListItemBar
                            title={
                              <Typography noWrap sx={{ fontSize: 13 }}>
                                {gallary.title}
                              </Typography>
                            }
                            subtitle={`@ ${moment(gallary?.created_at).format(
                              "YYYY"
                            )}`}
                            actionIcon={
                              <IconButton
                                sx={{
                                  color: "rgba(255, 255, 255, 0.54)",
                                }}
                                aria-label={`info about ${gallary.title}`}
                                onClick={() => handleGallaryView(gallary)}
                              >
                                <ReadMore color="secondary" />
                              </IconButton>
                            }
                          />
                        </ImageListItem>
                      ))}
                    </ImageList>
                  </Grid>
                </Grid>
              </Section>
            </SpacingContent>
          )}
        </>
      )}
    </>
  );
};

export default EventDetailsPage;
