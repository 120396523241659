import React, { useRef } from "react";
import { Swiper as SwiperContainer } from "swiper/react";
import { Autoplay, Pagination, Navigation, EffectCoverflow } from "swiper";

// Import Swiper styles
import "swiper/css";

import { Box, Button, Grid } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { colors } from "../../assets/utils/colors";

const PortifolioSwiper = ({ conHeight, bgColor, content, children }) => {
    const sliderRef = useRef();
    return (
        <Box
            sx={{
                width: { md: "100%", xs: "100%" },
                position: "relative",
                height: conHeight ? conHeight : 500,
                bgcolor: bgColor ? bgColor : "none",
            }}
        >
            {/* <Box
                sx={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            > */}
            <Grid
                container
                gap={2}
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                }}
            >
                <Grid item sm={11} xs={11}>
                    <SwiperContainer
                        navigation={{
                            prevEl: ".swiper-button-prev",
                            nextEl: ".swiper-button-next",
                        }}
                        initialSlide={0}
                        modules={[
                            Autoplay,
                            EffectCoverflow,
                            Pagination,
                            Navigation,
                        ]}
                        effect={"coverflow"}
                        grabCursor={true}
                        centeredSlides={true}
                        coverflowEffect={{
                            rotate: 50,
                            stretch: 0,
                            depth: 100,
                            modifier: 1,
                            slideShadows: true,
                        }}
                        pagination={{
                            el: `.${content}`,
                            type: "custom",
                            renderCustom: function (swiper, current, total) {
                                return current + "/" + total;
                            },
                        }}
                        // onSlideChange={() => console.log("slide change")}
                        onSwiper={(it) => (sliderRef.current = it)}
                        breakpoints={{
                            // when window width is >= 320px
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 24,
                            },
                            // when window width is >= 480px
                            480: {
                                slidesPerView: 1,
                                spaceBetween: 24,
                            },
                            // when window width is >= 640px
                            768: {
                                slidesPerView: 1,
                                spaceBetween: 24,
                            },
                            // when window width is >= 640px
                            800: {
                                slidesPerView: 1,
                                spaceBetween: 24,
                            },
                        }}
                    >
                        {children}
                    </SwiperContainer>
                </Grid>
                <Grid item sm={11} xs={11}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            zIndex: 999,
                            py: 2,
                        }}
                    >
                        <Button
                            size="small"
                            color="warning"
                            variant="outlined"
                            onClick={() => sliderRef.current?.slidePrev()}
                            startIcon={<ArrowBackIos />}
                            sx={{
                                px: 2,
                                fontWeight: "bold",
                                borderRadius: 2,
                                textTransform: "capitalize",
                            }}
                        >
                            Prev
                        </Button>
                        <Box
                            sx={{
                                height: 60,
                                width: 60,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: 100,
                                color: colors.secondary,
                                fontSize: 20,
                                fontWeight: "bold",
                            }}
                            className={`${content}`}
                        ></Box>
                        <Button
                            size="small"
                            color="warning"
                            variant="outlined"
                            onClick={() => sliderRef.current?.slideNext()}
                            endIcon={<ArrowForwardIos />}
                            sx={{
                                px: 2,
                                fontWeight: "bold",
                                borderRadius: 2,
                                textTransform: "capitalize",
                            }}
                        >
                            Next
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            {/* </Box> */}
        </Box>
    );
};

export default PortifolioSwiper;
