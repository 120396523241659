import { createSlice } from "@reduxjs/toolkit";
import { filter, isEmpty } from "lodash";
import moment from "moment";

// => initialState
const initialState = {
  events: null,
  upcomingEvent: null,
  eventPortifolios: null,
  eventDetails: null,
};
export const eventsSlice = createSlice({
  name: "eventsSlice",
  initialState,
  reducers: {
    setEvents: (state, actions) => {
      let allEvent = actions.payload;
      let upcomingEvent;
      let eventPortifolios;
      if (!isEmpty(allEvent)) {
        upcomingEvent = filter(
          allEvent,
          ({ event_date }) => moment(event_date) >= moment(new Date())
        );
        if (!isEmpty(upcomingEvent)) {
          upcomingEvent = upcomingEvent[0];
        }
        eventPortifolios = filter(
          allEvent,
          ({ event_date }) => moment(new Date()) >= moment(event_date)
        );
      }

      // => Set state
      state.upcomingEvent = upcomingEvent;
      state.eventPortifolios = eventPortifolios;
      state.gallaries = actions.payload;
    },
    setEventDetails: (state, action) => {
      state.eventDetails = action.payload;
    },
  },
});

export const { setEvents, setEventDetails } = eventsSlice.actions;
export default eventsSlice.reducer;
