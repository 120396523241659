// => Auth selector
export const authSelector = (state) => state.authReducer;

// => systemConfig selector
export const systemConfigSelector = (state) => state.systemConfigReducer;

// => gallaries selector
export const gallariesSelector = (state) => state.gallariesReducer;

// => event types selector
export const eventTypesSelector = (state) => state.eventTypesReducer;

// => clients selector
export const clientsSelector = (state) => state.clientsReducer;

// => partiners selector
export const partinersSelector = (state) => state.partinersReducer;

// => Events selector
export const eventsSelector = (state) => state.eventsReducer;
