import React from "react";
import Banner from "../components/Banner";
import OurServices from "../components/OurServices";
import VissionAndMission from "../components/VissionAndMission";
import OurClients from "../components/OurClients";
import EventTypes from "../components/EventTypes";
import ContactUs from "../components/ContactUs";
import EventGallaries from "../components/EventGallaries";
import OurPartiners from "../components/OurPartiners";
import UpcomingEventBanner from "./events/components/UpcomingEventBanner";
import EventsPortifolio from "../components/home/EventsPortifolio";
import { useSelector } from "react-redux";
import { eventsSelector, partinersSelector } from "../state/features/selectors";
import { isEmpty } from "lodash";

const Homepage = () => {
  // ############### Redux State #############
  const { upcomingEvent, eventPortifolios } = useSelector(eventsSelector);
  const { partiners } = useSelector(partinersSelector);

  return (
    <>
      {/* ########### Banner ############# */}
      <Banner />

      {/* ########### Vission and Mission ########## */}
      <VissionAndMission />

      {/* ########### Our service ########## */}
      {!isEmpty(upcomingEvent) && <UpcomingEventBanner event={upcomingEvent} />}

      {/* ########### Our service ########## */}
      <OurServices />

      {/* ########### Our Clients ########## */}
      <OurClients />

      {/* ########### Event types ########## */}
      <EventTypes />

      {/* ########### Events Portifolio ########## */}
      {!isEmpty(eventPortifolios) && <EventsPortifolio />}

      {/* ########### Event Gallaries ########## */}
      <EventGallaries />

      {/* ########### Contact us ########## */}
      <ContactUs />

      {/* ########### Our Partiners ########## */}
      {!isEmpty(partiners) && <OurPartiners />}
    </>
  );
};

export default Homepage;
