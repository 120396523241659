import { createSlice } from "@reduxjs/toolkit";

// => initialState
const initialState = {
  eventTypes: null,
};
export const eventTypesSlice = createSlice({
  name: "eventTypesSlice",
  initialState,
  reducers: {
    setEventTypes: (state, actions) => {
      state.eventTypes = actions.payload;
    },
  },
});

export const { setEventTypes } = eventTypesSlice.actions;
export default eventTypesSlice.reducer;
