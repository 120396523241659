import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../baseUrl/baseURL";
import { eventTypeEndpoints } from "./eventTypeEndpoints";

export const eventTypeAPI = createApi({
  reducerPath: "eventTypeAPI",
  baseQuery: fetchBaseQuery({ baseUrl: baseURL }),
  endpoints: (builder) => ({
    getPublicEventTypes: builder.query({
      query: () => eventTypeEndpoints.GET_ALL,
    }),
  }),
});

export const { useGetPublicEventTypesQuery, useLazyGetPublicEventTypesQuery } =
  eventTypeAPI;
