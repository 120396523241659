import React from 'react';
import { Avatar, Box, Button, Grid, useMediaQuery } from '@mui/material';
import Section from './Section';
import SpacingContent from './SpacingContent';
import ClientWidget from './widgets/ClientWidget';
import { take } from 'lodash';
import { colors } from '../assets/utils/colors';
import { ArrowRightAltRounded } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import client from '../assets/media/images/annual.JPG';
import { useSelector } from 'react-redux';
import { clientsSelector } from '../state/features/selectors';

const OurClients = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const { clients } = useSelector(clientsSelector);

    return (
        <>
            <SpacingContent>
                <Section
                    head={'We are trusted'}
                    subHead={'Pleasure to work with'}
                >
                    <Grid
                        container
                        spacing={4}
                        sx={{ px: { md: 0, xs: 2 }, mt: { md: -5, xs: -0.5 } }}
                    >
                        <Grid item sm={7}>
                            <Grid container spacing={2}>
                                {take(clients, matches ? 8 : 4)?.map(
                                    (client, index) => (
                                        <Grid item sm={6} xs={12} key={index}>
                                            <ClientWidget client={client} />
                                        </Grid>
                                    ),
                                )}
                            </Grid>
                            <Box sx={{ mt: 3, textAlign: 'right' }}>
                                <Link to={'/clients'}>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        sx={{
                                            'py': 0.5,
                                            'px': 4,
                                            'fontWeight': 'bold',
                                            // 'background': `linear-gradient(180deg, ${colors.bgColor1}, ${colors.secondary})`,
                                            'transition': `.5s all ease-in-out`,
                                            ':hover': {
                                                transform: `scale(1.03)`,
                                                transition: `.5s all ease-in-out`,
                                            },
                                        }}
                                        endIcon={<ArrowRightAltRounded />}
                                    >
                                        See More clients
                                    </Button>
                                </Link>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            sm={5}
                            sx={{ display: { md: 'block', xs: 'none' } }}
                        >
                            <Box
                                sx={{
                                    'position': 'relative',
                                    ':after': {
                                        content: "''",
                                        height: 400,
                                        width: { md: 150, xs: 25 },
                                        position: 'absolute',
                                        right: -20,
                                        top: -20,
                                        borderTop: `10px solid ${colors.bgColor6}`,
                                        borderRight: `10px solid ${colors.bgColor6}`,
                                        borderTopRightRadius: 110,
                                    },
                                }}
                            >
                                <Avatar
                                    src={client}
                                    variant="square"
                                    sx={{
                                        width: '100%',
                                        height: 500,
                                        borderTopRightRadius: 100,
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Section>
            </SpacingContent>
        </>
    );
};

export default OurClients;
