import { useEffect } from "react";
import { errorNotify, successNotify } from "../helpers/notify";
import {
  getRTKErrorMessage,
  getRTKResponseData,
  getRTKSuccessMessage,
} from "../helpers/RTKHelpers";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useLazyGetPublicGallariesQuery } from "../api/gallaryAPI/gallaryAPI";
import { setGallaries } from "../state/features/gallaries/gallariesSlice";
import { systemConfigSelector } from "../state/features/selectors";
import { disableMainFetch } from "../state/features/systemConfig/systemConfigSlice";
import { useLazyGetPublicEventsQuery } from "../api/eventAPI/eventAPI";
import { setEvents } from "../state/features/events/eventsSlice";
import { setPartiners } from "../state/features/partiners/partinersSlice";
import { setEventTypes } from "../state/features/eventTypes/eventTypesSlice";
import { setClients } from "../state/features/clients/clientsSlice";
import { useLazyGetPublicClientsQuery } from "../api/clientAPI/clientAPI";
import { useLazyGetPublicEventTypesQuery } from "../api/eventTypeAPI/eventTypeAPI";
import { useLazyGetPublicPartinersQuery } from "../api/partinerAPI/partinerAPI";

const useRTK = () => {
  // ############# Redux state #############
  const { isMainFetch } = useSelector(systemConfigSelector);

  // => Use RTK Response
  const useRTKResponse = (isSuccess, isError, error, data, actions) => {
    useEffect(() => {
      let isSubscribed = true;
      if (isSubscribed) {
        if (isSuccess) {
          actions && actions(getRTKResponseData(data));
          successNotify(getRTKSuccessMessage(data));
        }
        if (isError) {
          errorNotify(
            !isEmpty(error)
              ? getRTKErrorMessage(error)
              : "Some error occur. Please try again!"
          );
        }
      }
      return () => {
        isSubscribed = false;
      };
    }, [isSuccess, isError, error, data]);
  };

  // => Use RTK Fetch
  const useRTKFetch = (isSuccess, data, actions) => {
    useEffect(() => {
      let isSubscribed = true;
      if (isSubscribed) {
        if (isSuccess) {
          actions(getRTKResponseData(data));
        }
      }
      return () => {
        isSubscribed = false;
      };
    }, [isSuccess, data]);
  };

  // => RTK main data
  const useRTKMainData = () => {
    // => CONST
    const dispatch = useDispatch();

    // ################# Galleries #################
    const [
      getGallaries,
      {
        isLoading: gallaryLoading,
        isSuccess: gallarySuccess,
        data: gallaryData,
      },
    ] = useLazyGetPublicGallariesQuery();
    const rtkGallaryActions = (data) => {
      dispatch(setGallaries(data));
    };
    useRTKFetch(gallarySuccess, gallaryData, rtkGallaryActions);

    // ################# Events #################
    const [
      getEvents,
      { isLoading: eventLoading, isSuccess: eventSuccess, data: eventData },
    ] = useLazyGetPublicEventsQuery();
    const rtkEventActions = (data) => {
      dispatch(setEvents(data));
    };
    useRTKFetch(eventSuccess, eventData, rtkEventActions);

    // ################# Clients #################
    const [
      getCliesnts,
      { isLoading: clientLoading, isSuccess: clientSuccess, data: clientData },
    ] = useLazyGetPublicClientsQuery();
    const rtkClientActions = (data) => {
      dispatch(setClients(data));
    };
    useRTKFetch(clientSuccess, clientData, rtkClientActions);

    // ################# Event types #################
    const [
      getEventTypes,
      {
        isLoading: eventTypeLoading,
        isSuccess: eventTypeSuccess,
        data: eventTypeData,
      },
    ] = useLazyGetPublicEventTypesQuery();
    const rtkEventTypeActions = (data) => {
      dispatch(setEventTypes(data));
    };
    useRTKFetch(eventTypeSuccess, eventTypeData, rtkEventTypeActions);

    // ################# Partiners #################
    const [
      getpartiners,
      {
        isLoading: partinersLoading,
        isSuccess: partinersSuccess,
        data: partinersData,
      },
    ] = useLazyGetPublicPartinersQuery();
    const rtkPartinersActions = (data) => {
      dispatch(setPartiners(data));
    };
    useRTKFetch(partinersSuccess, partinersData, rtkPartinersActions);

    // ############## useEffect #################
    useEffect(() => {
      let isSubscribed = true;
      if (isSubscribed) {
        if (isMainFetch) {
          getGallaries();
          getEvents();
          getCliesnts();
          getEventTypes();
          getpartiners();
          setTimeout(() => {
            dispatch(disableMainFetch());
          }, 2000);
        }
      }
      return () => {
        isSubscribed = false;
      };
    }, []);

    return gallaryLoading
      ? gallaryLoading
      : eventLoading
      ? eventLoading
      : clientLoading
      ? clientLoading
      : eventTypeLoading
      ? eventTypeLoading
      : partinersLoading
      ? partinersLoading
      : false;
  };

  return {
    useRTKFetch,
    useRTKResponse,
    useRTKMainData,
  };
};

export default useRTK;
