import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../baseUrl/baseURL";
import { partinerEndpoints } from "./partinerEndpoints";

export const partinerAPI = createApi({
  reducerPath: "partinerAPI",
  baseQuery: fetchBaseQuery({ baseUrl: baseURL }),
  endpoints: (builder) => ({
    getPublicPartiners: builder.query({
      query: () => partinerEndpoints.GET_ALL,
    }),
  }),
});

export const { useGetPublicPartinersQuery, useLazyGetPublicPartinersQuery } =
  partinerAPI;
