import { Avatar, Box, Grid, Typography } from "@mui/material";
import FsLightbox from "fslightbox-react";
import React from "react";
import { colors } from "../assets/utils/colors";
import moment from "moment";

const EventGallaryView = ({ toggler, gallary }) => {
  return (
    <>
      <FsLightbox
        toggler={toggler}
        sources={[
          <Box
            sx={{
              height: `100vh`,
              width: "100vw",
              position: "relative",
              background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
            }}
          >
            <Grid container>
              <Grid item sm={12}>
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    src={gallary?.image}
                    variant="square"
                    sx={{
                      width: "100%",
                      height: "100%",
                      img: {
                        objectFit: "contain",
                      },
                    }}
                  />
                  <Box
                    sx={{
                      height: 80,
                      position: "absolute",
                      bottom: 0,
                      right: 0,
                      left: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { md: 20, xs: 16 },
                        textTransform: "uppercase",
                        color: colors.secondary,
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      {gallary?.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { md: 17, xs: 14 },
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        opacity: 0.8,
                        textAlign: "center",
                      }}
                    >
                      {`@ ${moment(gallary?.created_at).format("YYYY")}`}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>,
        ]}
      />
    </>
  );
};

export default EventGallaryView;
