import { Box, Typography } from "@mui/material";
import React from "react";
import Countdown from "react-countdown";
import TimerCountWidget from "./widgets/TimerCountWidget";
import { colors } from "../assets/utils/colors";
import moment from "moment";

const EventCountDown = ({ eventDate }) => {
  const Completionist = () => (
    <Box
      sx={{
        p: 1,
        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor4})`,
      }}
    >
      <Typography
        sx={{
          p: 1,
          textAlign: "center",
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        {moment(eventDate).format("LL")}
      </Typography>
      <Typography
        sx={{
          fontSize: 14,
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        Event completed successfull
      </Typography>
    </Box>
  );

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
          gap={2}
        >
          <TimerCountWidget item={"Days"} value={days} />
          <TimerCountWidget item={"Hours"} value={hours} />
          <TimerCountWidget item={"Minutes"} value={minutes} />
          <TimerCountWidget item={"Seconds"} value={seconds} />
        </Box>
      );
    }
  };
  return (
    <>
      <Countdown
        date={Date.parse(moment(eventDate).format("YYYY-MM-DD"))}
        renderer={renderer}
      />
    </>
  );
};

export default EventCountDown;
