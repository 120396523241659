import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../pages/auth/Login";
import GuestRoutes from "./GuestRoutes";
import Homepage from "../pages/Homepage";
import HomeLayout from "../pages/layouts/HomeLayout";
import AboutUs from "../pages/AboutUs";
import Gallaries from "../pages/Gallaries";
import Clients from "../pages/Clients";
import Services from "../pages/Services";
import EventTypes from "../pages/EventTypes";
import UpcomingEventPage from "../pages/upcomingEvents/UpcomingEventPage";
import UpcomingEventsDetailsPage from "../pages/upcomingEvents/UpcomingEventsDetailsPage";
import EventsPage from "../pages/events/EventsPage";
import EventDetailsPage from "../pages/events/EventDetailsPage";

// ?############## GUEST ELEMENTS ###########
const LoginElement = () => <Login />;
// => HomepageElement
const HomepageElement = () => (
    <HomeLayout>
        <Homepage />
    </HomeLayout>
);
// => ServicesElement
const ServicesElement = () => (
    <HomeLayout>
        <Services />
    </HomeLayout>
);
// => ClientsElement
const ClientsElement = () => (
    <HomeLayout>
        <Clients />
    </HomeLayout>
);
// => GallariesElement
const GallariesElement = () => (
    <HomeLayout>
        <Gallaries />
    </HomeLayout>
);
// => AboutUsElement
const AboutUsElement = () => (
    <HomeLayout>
        <AboutUs />
    </HomeLayout>
);
// => EventTypesElement
const EventTypesElement = () => (
    <HomeLayout>
        <EventTypes />
    </HomeLayout>
);
// => UpcomingEventElement
const UpcomingEventElement = () => (
    <HomeLayout>
        <UpcomingEventPage />
    </HomeLayout>
);
// => UpcomingEventElement
const UpcomingEventDetailsElement = () => (
    <HomeLayout>
        <UpcomingEventsDetailsPage />
    </HomeLayout>
);
// => Event portifolios
const EventsElement = () => (
    <HomeLayout>
        <EventsPage />
    </HomeLayout>
);
// => Event details
const EventDetailsElement = () => (
    <HomeLayout>
        <EventDetailsPage />
    </HomeLayout>
);

// !############## MAIN FUNC ###########
const App = () => {
    return (
      <React.Fragment>
        <Routes>
          {/* ################## GUEST ROUTES ################## */}
          <Route element={<GuestRoutes />}>
            <Route path="/portal" element={<LoginElement />} />
            <Route path="/" element={<HomepageElement />} />
            <Route path="/services" element={<ServicesElement />} />
            <Route path="/clients" element={<ClientsElement />} />
            <Route path="/gallaries" element={<GallariesElement />} />
            <Route path="/event-portifolios" element={<EventsElement />} />
            <Route
              path="/event-portifolios/:event_id"
              element={<EventDetailsElement />}
            />
            <Route path="/event-types" element={<EventTypesElement />} />
            <Route path="/about-us" element={<AboutUsElement />} />
            <Route path="/upcoming-events" element={<UpcomingEventElement />} />
            <Route
              path="/upcoming-events/:event_id"
              element={<UpcomingEventDetailsElement />}
            />
          </Route>
        </Routes>
      </React.Fragment>
    );
};

export default App;
