import React from "react";
import SpacingContent from "../SpacingContent";
import Section from "../Section";
import { Box, Grid, Typography } from "@mui/material";
import EventPortifolioWidget from "../widgets/EventPortifolioWidget";
import PortifolioSwiper from "../swipers/PortifolioSwiper";
import { SwiperSlide } from "swiper/react";
import { colors } from "../../assets/utils/colors";
import { eventsSelector } from "../../state/features/selectors";
import { useSelector } from "react-redux";

const EventsPortifolio = () => {
  // ############### Redux State #############
  const { eventPortifolios } = useSelector(eventsSelector);

  return (
    <>
      <SpacingContent maxPadding={3}>
        <Section>
          <Box
            sx={{
              p: 4,
              borderRadius: 5,
            }}
            className="animated-bg2"
          >
            <Grid container>
              <Grid item sm={6}>
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    p: 2,
                  }}
                >
                  {/* <SectionBgGradient /> */}

                  <Typography
                    sx={{
                      fontSize: 50,
                      fontWeight: "bold",
                      color: colors.textColor,
                    }}
                  >
                    Our Events
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 35,
                      fontWeight: "bold",
                      color: colors.secondary,
                    }}
                  >
                    Portifolios
                  </Typography>
                  <Typography
                    sx={{
                      mt: 4,
                      color: colors.textColor,
                      fontSize: 14,
                    }}
                  >
                    Welcome to our Event Portfolios section! Here, we showcase
                    the diverse range of events we've had the pleasure of
                    organizing. From corporate gatherings to intimate weddings,
                    our portfolio highlights our commitment to excellence and
                    creativity. Each portfolio entry includes: <br />
                    <br /> <strong>Event Photos:</strong> Capturing the essence
                    and atmosphere of each event.
                    <br /> <strong>Event Details:</strong> A brief overview of
                    the event, including the theme, location, and special
                    features.
                    <br />
                    <br /> Explore our portfolios to see how we bring visions to
                    life and create unforgettable experiences. Whether you're
                    planning a grand celebration or a small, private event, our
                    team is dedicated to making your event a success.
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={6} sx={{ zIndex: 1024 }}>
                <Grid container justifyContent={"center"}>
                  <Grid item sm={10}>
                    <PortifolioSwiper conHeight={"100%"} content="services">
                      {eventPortifolios.map((event, index) => (
                        <SwiperSlide key={index}>
                          <EventPortifolioWidget event={event} />
                        </SwiperSlide>
                      ))}
                    </PortifolioSwiper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Section>
      </SpacingContent>
    </>
  );
};

export default EventsPortifolio;
