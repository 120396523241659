import { Avatar, Box, Button, Typography } from "@mui/material";
import React from "react";
import eventImg from "../../assets/media/images/foundrize.jpg";
import {
  ArrowCircleRightOutlined,
  OutboundOutlined,
} from "@mui/icons-material";
import { colors } from "../../assets/utils/colors";
import { Link } from "react-router-dom";

const EventPortifolioWidget = ({ event }) => {
  return (
    <>
      <Box
        sx={{
          height: 500,
          bgcolor: "red",
          position: "relative",
          borderRadius: 5,
          overflow: "hidden",
        }}
      >
        <Avatar
          src={event.tease_image}
          variant="square"
          sx={{ width: "100%", height: "100%" }}
        />
        <Box
          sx={{
            position: "absolute",
            bottom: 20,
            left: 20,
            right: 20,
            borderRadius: 5,
            padding: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            background: `linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, .7))`,
          }}
        >
          <Typography
            sx={{
              fontSize: 20,
              textAlign: "center",
              fontStyle: "italic",
              color: colors.bgColor1,
            }}
          >
            {event.event_title}
          </Typography>
          <Link to={`/event-portifolios/${event.id}`}>
            <Button
              color="secondary"
              sx={{
                borderRadius: `50px !important`,
                pr: {
                  md: 10,
                  sm: 10,
                  xs: 8,
                },
                mt: 2,
                pl: { md: 5, sm: 5, xs: 3 },
                py: 1,
                boxShadow: `0px 0px 5px ${colors.secondary}`,
                border: `1px double ${colors.secondary}`,
                ":hover": {
                  ".arrow": {
                    transform: `rotate(45deg)`,
                    transition: `.3s all ease-in-out`,
                  },
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    md: 14,
                    sm: 14,
                    xs: 12,
                  },
                }}
              >
                Dig into the Details
              </Typography>
              <OutboundOutlined
                className="arrow"
                sx={{
                  position: "absolute",
                  right: 15,
                  transition: `.3s all ease-in-out`,
                  fontSize: {
                    md: 25,
                    sm: 25,
                    xs: 20,
                  },
                  color: colors.secondary,
                }}
              />
            </Button>
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default EventPortifolioWidget;
