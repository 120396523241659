import { Avatar, Box, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/colors';

const ClientWidget = ({ client }) => {
    return (
        <>
            <a
                href={client.url}
                target="_blank"
                key={client.id}
                rel="noreferrer"
            >
                <Box
                    sx={{
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                        p: 2,
                        borderRadius: 2,
                        display: 'flex',
                        alignItems: 'center',
                        border: `1px solid ${colors.bgColor4}`,
                    }}
                >
                    <Box>
                        <Box
                            sx={{
                                borderRadius: 3,
                                mr: 1,
                                background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor4})`,
                            }}
                        >
                            <Avatar
                                variant="square"
                                src={client.image}
                                sx={{
                                    height: 70,
                                    width: 70,
                                    borderRadius: 3,
                                    img: { objectFit: 'cover' },
                                }}
                            />
                        </Box>
                    </Box>
                    <Box>
                        <Typography
                            sx={{
                                fontSize: 14,
                                color: colors.textColor,
                                opacity: 0.7,
                            }}
                        >
                            {client.name}
                        </Typography>
                        <Typography
                            sx={{
                                color: colors.textColor,
                                fontWeight: 'bold',
                                fontSize: 16,
                                opacity: 0.7,
                            }}
                        >
                            {client.year}
                        </Typography>
                    </Box>
                </Box>
            </a>
        </>
    );
};

export default ClientWidget;
