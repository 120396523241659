import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../baseUrl/baseURL";
import { eventEndpoints } from "./eventEndpoints";

export const eventAPI = createApi({
  reducerPath: "eventAPI",
  baseQuery: fetchBaseQuery({ baseUrl: baseURL }),
  endpoints: (builder) => ({
    getPublicEvents: builder.query({
      query: () => `${eventEndpoints.GET_ACTIVE_EVENTS}`,
    }),
    getPublicEventById: builder.query({
      query: (payload) => `${eventEndpoints.GET_ACTIVE_EVENT_BY_ID}/${payload}`,
    }),
  }),
});

export const {
  useGetPublicEventsQuery,
  useLazyGetPublicEventsQuery,
  useGetPublicEventByIdQuery,
} = eventAPI;
