import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../baseUrl/baseURL";
import { clientEndpoints } from "./clientEndpoints";

export const clientAPI = createApi({
  reducerPath: "clientAPI",
  baseQuery: fetchBaseQuery({ baseUrl: baseURL }),
  endpoints: (builder) => ({
    getPublicClients: builder.query({
      query: () => clientEndpoints.GET_ALL,
    }),
  }),
});

export const { useGetPublicClientsQuery, useLazyGetPublicClientsQuery } =
  clientAPI;
